import ApiCall from "../../api.utils";
import { ISavingsPot } from '../../../dto/savings.pot.dto';

const GetSavingsPotsTotals = (): Promise<ISavingsPot[]> => {
    return ApiCall('/accounts/savings-pots', {
        'method': 'GET', 
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(
        response => response.json()
    ).then((items): ISavingsPot[] => {
        return items;
    });
}

export default GetSavingsPotsTotals;