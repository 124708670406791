import React, { useEffect, useState } from 'react';
import { ISavingsPot } from '../../../dto/savings.pot.dto';
import GetSavingsPotsTotals from '../../../utilities/api/accounts/get.savings.pots.totals';

export const SavingsTotalsPanel = () => {

	const [total, setTotal] = useState<number>(0);
	const [savingsPots, setSavingsPots] = useState<ISavingsPot[]>([]);

	useEffect(() => {
        GetSavingsPotsTotals().then((result: ISavingsPot[]) => {
            setSavingsPots(result);
            let savingsTotal: number = 0;
            result.forEach((p) => { savingsTotal += p.totals.total });
            setTotal(savingsTotal);
        });

	}, []);

	return <>
		<div className="flex justify-between">
			<h2 className="flex font-bold text-2xl mb-5">
				Savings pots
			</h2>
			<p className={total >= 0 ? 'text-green-500 text-xl font-bold min-w-max' : 'text-red-500 text-xl font-bold min-w-max'}>
                {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(total)}
			</p>
		</div>
		{savingsPots.length > 0 ? savingsPots.map((pot: ISavingsPot) => {
			let potTotal = pot.totals.total;
			if (potTotal === undefined) potTotal = 0;
			let totalWidth = ((potTotal > 0 ? potTotal : 0) / pot.goal)*100;

            if (totalWidth > 100) totalWidth = 100;
            
			return <div className="mb-2" key={"savings-pot-" + pot.id}>
				<div className="flex justify-between mb-1">
					<h3 className="text-md font-bold">{pot.name}</h3>
					<p className={pot.totals.total === 0 || pot.totals.total === null ? 'text-gray-500' : pot.totals.total >= 0 ? 'text-green-500' : 'text-red-500'}>
                        {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(pot.totals.total)}
                    </p>
				</div>

				<div className="w-full bg-gray-300 rounded-md relative">
					<div className="bg-green-600 rounded-md relative z-10 transition-all" style={{width: totalWidth+"%"}}>
						<p className="text-xs text-white pt-1 pr-2 pb-0 pl-2"></p>
					</div>
				</div>

			</div>

		}) : <p>No savings pots found.</p>}
	</>;
}