import { ReactElement } from "react";

interface IBankTotalPanelProps {
	refresh: boolean;
}

const BankTotalPanel = (props: IBankTotalPanelProps): ReactElement<any, any> => {
    return <>{props.refresh}</>;
}

export default BankTotalPanel;