import { IRecurringTransaction } from '../../../../../dto/recurring.transaction.dto';
import { Icon } from '../../../../shared/components/icon.component';

export const RecurringTransaction = (props: {
	recurringTransaction: IRecurringTransaction
	refreshCallback: Function;
}) => {
	// const [showDeleteModal, setShowDeleteModal] = useState<boolean | null>(null);
	
	// useEffect(() => {
	// 	if (showDeleteModal !== null) {
	// 		setShowDeleteModal(null);
	// 	}
	// }, [showDeleteModal])

	// function processTransaction(processed: boolean) {
	// 	// ProcessPlannedTransaction(props.plannedTransaction.id, processed);
	// 	props.refreshCallback(true);
	// }

	// function deletedCallback() {
	// 	props.refreshCallback(true);
	// 	setShowDeleteModal(false);
	// }

	return <div className="flex border-b pt-1 pb-1 flex-col md:flex-row" key={'planned-transaction-' + props.recurringTransaction.id}>
		<div>
			<Icon type={props.recurringTransaction.category.code} />
		</div>
		<h5 className="md:pl-5 md:pr-5 w-full" >
			{props.recurringTransaction.description}
		</h5>
		<p className={props.recurringTransaction.amount >= 0 ? 'text-green-500 min-w-max' : 'text-red-500 min-w-max'} >
            {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(props.recurringTransaction.amount)}
		</p>
		{/* <div className="md:pl-5">
			<button type="button" onClick={(e) => {setShowDeleteModal(true);}} className="text-red-700 cursor-pointer bg-white rounded-full align-top">
				<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
			</button>
			<Modal open={showDeleteModal}>
				<DeletePlannedTransaction plannedTransaction={props.plannedTransaction} successCallback={deletedCallback} cancelCallback={() => {setShowDeleteModal(false)}} />
			</Modal>
		</div> */}
	</div>
}