import moment from "moment";
import { LoggedInTemplate } from "../../../../../templates/loggedin/base";
import { AccountTotalsChart } from "../account.totals.chart.component";
import { AccountNavigation } from "../account.navigation.component";
import { IAccount } from "../../../../../dto/account.dto";
import { AccountTotal } from "../account.total.component";

export const RetirementAccountView = (props: {
    bankAccount: IAccount;
    changeDate: Function;
    date: moment.Moment;
    dateFrom: string;
    dateTo: string;
    refresh: boolean;
    setRefresh: Function;
}) => {
    const bankAccount = props.bankAccount;
    const changeDate = props.changeDate;
    const date = props.date;
    const dateFrom = props.dateFrom;
    const dateTo = props.dateTo;
    const refresh = props.refresh;
    const setRefresh = props.setRefresh;
    
    return <>
        <LoggedInTemplate title={bankAccount !== null ? bankAccount.name : "Loading..."} chart={<AccountTotalsChart accountId={bankAccount.accountId} dateFrom={moment(dateFrom).subtract({years: 30})} dateTo={moment(dateTo)} refresh={false} />} breadcrumb={[{link: '/bank-accounts', text: 'Bank accounts'}, {link: '', text: (bankAccount !== null ? bankAccount.name : "Loading...")}]}>
        {refresh ? <p>Loading...</p> : <>
            <div className="max-w-7xl mx-auto -mt-8">
                <div className="flex flex-wrap items-start flex-row justify-between bg-white rounded-xl p-10">
                    <AccountNavigation date={date} changeDate={changeDate} />
                </div>
            </div>

            <div className="max-w-7xl mx-auto mt-10 flex flex-wrap pl-5 pr-5">
                <div className="w-full md:w-6/12 md:pr-5">
                    <AccountTotal accountId={bankAccount.accountId} dateFrom={moment(dateFrom)} dateTo={moment(dateTo)} refresh={refresh} setRefresh={setRefresh} />
                </div>
            </div>
            </>}
		</LoggedInTemplate>
    </>;
}