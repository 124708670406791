import moment from "moment";
import { IAccount } from "../../../../../dto/account.dto";
import { LoggedInTemplate } from "../../../../../templates/loggedin/base";
import { AccountNavigation } from "../account.navigation.component";
import { AccountTotalsChart } from "../account.totals.chart.component";
import { AccountTotal } from "../account.total.component";
import { AccountTotalMinusBills } from "../account.total.minus.bills.component";
import { PlannedTransactionsPanel } from "../list.planned.transactions.component";
import { SavingsTotalsPanel } from "../savings.pot.totals.component";

export const InvestmentAccountView = (props: {
    bankAccount: IAccount;
    changeDate: Function;
    date: moment.Moment;
    dateFrom: string;
    dateTo: string;
    refresh: boolean;
    setRefresh: Function;
}) => {
    const bankAccount = props.bankAccount;
    const changeDate = props.changeDate;
    const date = props.date;
    const dateFrom = props.dateFrom;
    const dateTo = props.dateTo;
    const refresh = props.refresh;
    const setRefresh = props.setRefresh;
    
    return <>
        <LoggedInTemplate title={bankAccount !== null ? bankAccount.name : "Loading..."} chart={<AccountTotalsChart accountId={bankAccount.accountId} dateFrom={moment(dateFrom).subtract({years: 30})} dateTo={moment(dateTo)} refresh={false} />} breadcrumb={[{link: '/bank-accounts', text: 'Bank accounts'}, {link: '', text: (bankAccount !== null ? bankAccount.name : "Loading...")}]}>
        {refresh ? <p>Loading...</p> : <>
            <div className="max-w-7xl mx-auto -mt-8">
                <div className="flex flex-wrap items-start flex-row justify-between bg-white rounded-xl p-10">
                    <AccountNavigation date={date} changeDate={changeDate} />
                </div>
            </div>
            
            <div className="max-w-7xl mx-auto mt-10 flex flex-wrap pl-5 pr-5">
                <div className="w-full md:w-6/12 md:pr-5">
                    <AccountTotal accountId={bankAccount.accountId} dateFrom={moment(dateFrom)} dateTo={moment(dateTo)} refresh={refresh} setRefresh={setRefresh} />
                </div>
                <div className="w-full md:w-6/12 md:pl-5">
                    <AccountTotalMinusBills accountId={bankAccount.accountId} dateFrom={moment(dateFrom)} dateTo={moment(dateTo)} refresh={refresh} />
                </div>
            </div>
            <div className="max-w-7xl mx-auto mt-5 flex flex-wrap pl-5 pr-5">
                <div className="w-full md:w-6/12">
                    <div className="md:pr-5">
                        <div className="p-10 mb-10 bg-white rounded-xl shadow-lg border">
                            <PlannedTransactionsPanel accountId={bankAccount.accountId} dateFrom={moment(dateFrom)} dateTo={moment(dateTo)} refresh={refresh} refreshCallback={setRefresh} />
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-6/12 mb-10">
                    <div className="md:pl-5">
                        <div className="p-10 bg-white rounded-xl shadow-lg border">
                            <SavingsTotalsPanel accountId={bankAccount.accountId} dateTo={moment(dateTo)} refresh={refresh} />
                        </div>
                    </div>
                </div>
            </div></>}
		</LoggedInTemplate>
    </>;
}