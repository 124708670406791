import moment from "moment";
import { IAccount } from "../../../../../dto/account.dto";
import { LoggedInTemplate } from "../../../../../templates/loggedin/base";
import { AccountNavigation } from "../account.navigation.component";
import { AccountTotal } from "../account.total.component";
import { AccountTotalMinusBills } from "../account.total.minus.bills.component";
import { PlannedTransactionsPanel } from "../list.planned.transactions.component";
import { SavingsTotalsPanel } from "../savings.pot.totals.component";
import { AccountTotalsChart } from "../account.totals.chart.component";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React from "react";
import Container from "@mui/material/Container";


export const BankAccountView = (props: {
    bankAccount: IAccount;
    changeDate: Function;
    date: moment.Moment;
    dateFrom: string;
    dateTo: string;
    refresh: boolean;
    setRefresh: Function;
}) => {
    const bankAccount = props.bankAccount;
    const changeDate = props.changeDate;
    const date = props.date;
    const dateFrom = props.dateFrom;
    const dateTo = props.dateTo;
    const refresh = props.refresh;
    const setRefresh = props.setRefresh;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function CustomTabPanel(props: any) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                {children}
              </Box>
            )}
          </div>
        );
      }

    return <>
        <LoggedInTemplate title={bankAccount !== null ? bankAccount.name : "Loading..."} chart={null} breadcrumb={[{link: '/bank-accounts', text: 'Bank accounts'}, {link: '', text: (bankAccount !== null ? bankAccount.name : "Loading...")}]}>
        {refresh ? <p>Loading...</p> : <>
            <div className="max-w-7xl mx-auto -mt-8">
                <div className="flex flex-wrap items-start flex-row justify-between bg-white rounded-xl p-10">
                    <AccountNavigation date={date} changeDate={changeDate} />
                </div>
            </div>
            
            <div className="max-w-7xl mx-auto mt-10 flex flex-wrap pl-5 pr-5">
                <div className="w-full md:w-6/12 md:pr-5">
                    <AccountTotal accountId={bankAccount.accountId} dateFrom={moment(dateFrom)} dateTo={moment(dateTo)} refresh={refresh} setRefresh={setRefresh} />
                </div>
                <div className="w-full md:w-6/12 md:pl-5">
                    <AccountTotalMinusBills accountId={bankAccount.accountId} dateFrom={moment(dateFrom)} dateTo={moment(dateTo)} refresh={refresh} />
                </div>
            </div>
            <Container>
                <Grid item xs={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Planned Transactions" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                            <Tab label="Savings Totals" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <PlannedTransactionsPanel accountId={bankAccount.accountId} dateFrom={moment(dateFrom)} dateTo={moment(dateTo)} refresh={refresh} refreshCallback={setRefresh} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <SavingsTotalsPanel accountId={bankAccount.accountId} dateTo={moment(dateTo)} refresh={refresh} />
                    </CustomTabPanel>
                </Grid>
            </Container></>}
		</LoggedInTemplate>
    </>;
}