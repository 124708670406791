

import ApiCall from "../../api.utils";
import { IAccountChartTotal } from '../../../dto/account.chart.total.dto';

const GetAccountChartTotals = (accountId: number, dateFrom: string, dateTo: string): Promise<IAccountChartTotal[]> => {
    return ApiCall('/accounts/' + accountId + '/account-balance-chart?dateFrom=' + dateFrom + '&dateTo=' + dateTo, {
        'method': 'GET', 
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(
        response => response.json()
    ).then((items): IAccountChartTotal[]=> {
        return items;
    });
}

export default GetAccountChartTotals;