import moment from 'moment';
import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { IAccount } from '../../../dto/account.dto';
import GetAccount from '../../../utilities/api/accounts/get.account';
import { useSearchParams } from 'react-router-dom';
import { BankAccountView } from './inc/accounts/bank.account.component';
import { InvestmentAccountView } from './inc/accounts/investment.account.component';
import { RetirementAccountView } from './inc/accounts/retirement.account.component';
import { PropertyOverview } from './inc/accounts/property.overview.component';

export const ViewAccount = () => {

    let params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const [bankAccount, setBankAccount] = useState<IAccount | null | undefined>(null);
	const [date, setDate] = useState<moment.Moment>(getSelectedMonth());
	const [dateFrom, setDateFrom] = useState<string>(getDateFrom());
	const [dateTo, setDateTo] = useState<string>(getDateTo());
	const [refresh, setRefresh] = useState<boolean>(false);

	function getSelectedMonth(): moment.Moment {
		let date = moment();
		if (searchParams.get('date') !== null) {
			date = moment(searchParams.get('date'));
			if (date.date() >= 25) date.add({months: 1});
		} else if (date.date() >= 25) {
			date.add({months: 1});
		}

		date.date(24).hour(23).minute(59).second(59).millisecond(999);

		return date;
	}

	useEffect(() => {
		GetAccount(Number(params.accountId)).then((result: IAccount) => {
			setBankAccount(result);
		});
		setRefresh(false);
	}, [params.accountId, refresh]);

	function getDateFrom() {
		// 10-01-2021: 25-12-2020T00:00:00.000Z
		// 23-01-2021: 25-12-2020T00:00:00.000Z
		// 26-01-2021: 25-01-2020T00:00:00.000Z
		let dateFrom = moment(date).date(25).hour(0).minute(0).second(0).millisecond(0);
		return (date.date() < 25) ? dateFrom.subtract({months: 1}).toISOString() : dateFrom.toISOString();
	}

	function getDateTo() {
		// 10-01-2021: 24-01-2020T23:59:59.999Z
		// 23-01-2021: 24-01-2020T23:59:59.999Z
		// 26-01-2021: 24-02-2020T23:59:59.999Z
		let dateTo = moment(date).date(24).hour(23).minute(59).second(59).millisecond(999);
		return (date.date() >= 25) ? dateTo.add({months: 1}).toISOString() : dateTo.toISOString();
	}

	function changeDate(increment: number) {
		let parsedDate = moment(date.add({months: increment})).date(24).hour(23).minute(59).second(59).millisecond(999);
		setSearchParams({'date': parsedDate.format("yyyy-MM-DD")});
		setDate(date);
		setDateFrom(getDateFrom);
		setDateTo(getDateTo);
		setRefresh(true);
	}

	let component = <></>

	if (bankAccount !== undefined 
		&& bankAccount !== null) {
		if (bankAccount.accountType.code === 'investment') {
			component = <InvestmentAccountView 
				key={bankAccount.accountId}
				bankAccount={bankAccount}
				changeDate={changeDate}
				date={date}
				dateFrom={dateFrom}
				dateTo={dateTo}
				refresh={refresh}
				setRefresh={setRefresh}
			/>
		} else if (bankAccount.accountType.code === 'retirement') {
			component = <RetirementAccountView 
				key={bankAccount.accountId}
				bankAccount={bankAccount}
				changeDate={changeDate}
				date={date}
				dateFrom={dateFrom}
				dateTo={dateTo}
				refresh={refresh}
				setRefresh={setRefresh}
			/>
		} else if (bankAccount.accountType.code === 'housing') {
			component = <PropertyOverview 
				key={bankAccount.accountId}
				bankAccount={bankAccount}
				changeDate={changeDate}
				date={date}
				dateFrom={dateFrom}
				dateTo={dateTo}
				refresh={refresh}
				setRefresh={setRefresh}
			/>
		} else {
			component = <BankAccountView
				key={bankAccount.accountId}
				bankAccount={bankAccount}
				changeDate={changeDate}
				date={date}
				dateFrom={dateFrom}
				dateTo={dateTo}
				refresh={refresh}
				setRefresh={setRefresh}
			/>
		}
	}

	return component;

}