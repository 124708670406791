import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AuthContext from '../../../utilities/auth.context';

const UserMenu = () => {

	let auth = React.useContext(AuthContext);
    
    const [showMenu, setShowMenu] = useState<boolean>(false);

	function onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		setShowMenu(!showMenu);
	}

	function logout(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		e.preventDefault();
		auth.signout();
		window.location.reload();
	}

	function getInitials() {
		return localStorage.getItem("user.initials");
	}

	return <>
			<div className="ml-3 relative">
				<div>
					<button className="max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-haspopup="true" onClick={(e) => onClick(e)}>
						<span className="sr-only">Open user menu</span>
						<span className="h-8 w-8 flex bg-gray-700 text-green-300 px-2 py-1.5 text-sm font-medium rounded-full">{getInitials()}</span>
					</button>
				</div>

				<div className={"origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" + (showMenu ? "" : " hidden")} role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
					{auth.isAdmin() ? <>
						<NavLink to="/users" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">User management</NavLink>
						<NavLink to="/banks" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Bank management</NavLink>
						<hr/>
					</> : <></>}
					<NavLink to={"/profile"} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Your Profile</NavLink>
					<NavLink to={"/settings"} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</NavLink>
					<span className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" role="menuitem" onClick={logout}>Sign out</span>
				</div>
			</div>
		</>

}

export default UserMenu;