import React from 'react';

class LoggedInFooter extends React.Component {

	render() {
		return <div className="max-w-7xl mx-auto text-right mt-10 mb-10 pl-5 pr-5 lg:pl-0 lg:pr-0"><p className="text-sm text-gray-400">&copy; Copyright 2021</p></div>;
	}
}

export default LoggedInFooter;
