import { Page } from "../../../dto/page.dto";
import { IUser } from "../../../dto/user.dto";
import ApiCall from "../../api.utils";

const UsersList = (): Promise<Page<IUser>> => {
    return ApiCall('/users', {
        'method': 'GET', 
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json; charset=UTF-8'
        }
    }).then(users => users.json());
}

export default UsersList;