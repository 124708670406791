
export const TableData:React.FunctionComponent<{
	data: any;
    headerMapping: string[];
    fullHeaderIdx: number | undefined;
    dataMapping: any;
    actions: any | undefined;
}> = (props) => {



    return <table className={'border-collapse table-auto w-full text-sm shadow-lg rounded-xl overflow-hidden'}>
        <thead className={'bg-slate-100'}>
            <tr>
                {props.headerMapping.map((header: string, index: number) => {
                    return <th className={'border-b font-medium p-4 pl-8 text-slate-400 text-left' + (props.fullHeaderIdx !== undefined && props.fullHeaderIdx === index ? ' w-full' : '')} key={"table-data-header-" + index}>{header}</th>
                })}
                {props.actions !== undefined ? <th className={'border-b font-medium p-4 pl-8 text-slate-400 text-left'}>Actions</th> : <></>}
            </tr>
        </thead>
        <tbody className={'bg-white'}>
            {props.data.map((row: any, index: number) => {
                const rowMapping = props.dataMapping(row);

                return <tr key={"table-data-row-" + index}>
                    {rowMapping.map((column: any, index: number) => {
                        return <td className={'border-b border-slate-100 p-4 pl-8 text-slate-500'} key={"table-data-" + index}>{column}</td>
                    })}
                    {props.actions !== undefined ? <td className={'border-b border-slate-100 p-4 pl-8 text-slate-500'}>{props.actions(row)}</td> : <></>}
                </tr>
            })}
        </tbody>
    </table>
}