import React, {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { IBank } from '../../../dto/bank.dto';
import { LoggedInTemplate } from '../../../templates/loggedin/base';
import BanksList from '../../../utilities/api/banks/banks.list';
import DeleteBank from '../../../utilities/api/banks/delete.bank';
import DeleteModal from '../../shared/components/delete.modal.component';
import { Modal } from '../../shared/components/modal.component';
import { TableData } from '../../shared/components/table.data.component';

const BanksListing = () => {

    const [refresh, setRefresh] = useState<boolean>(false);
    const [banks, setBanks] = useState<IBank[] | undefined>(undefined);
    const [openNewBankModal, setOpenNewBankModal] = useState<boolean>(false);
    const [newBankSuccess, setNewBankSuccess] = useState<boolean>(false);

    const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
    const [openDeleteModal, setOpeDeleteModal] = useState<boolean>(false);

	useEffect(() => {
		if (refresh) setRefresh(false);
        if (newBankSuccess) {
            setNewBankSuccess(false);
            setOpenNewBankModal(false);
        }
        loadBanks();
	}, [refresh, newBankSuccess]);

    const loadBanks = () => {
        BanksList().then(response => {
            setBanks(response.content);
        });
    }

    const deleteBank = (bankId: number | undefined) => {
        if (bankId === undefined) return;
        DeleteBank(bankId).then(() => {
            setRefresh(true);
        });
    }

    return <LoggedInTemplate title="banks" chart={<></>}>
        <div className="max-w-7xl mx-auto -mt-8">
            <div className="flex flex-wrap items-start flex-row">
                {banks === undefined ? <p>Loading...</p> : (banks.length > 0 ? <TableData data={banks} headerMapping={[
                    '#',
                    'Name',
                    'Description',
                    'Logo'
                ]} fullHeaderIdx={2} dataMapping={(row :any | undefined) :any => { return [
                    row?.bankId,
                    row?.name,
                    row?.description,
                    row?.logo
                ]}} actions={(row:any):any => {
                    return <>
                        <NavLink to={"/banks/" + row?.bankId} className={'bg-green-100 p-3 pl-5 pr-5 rounded-lg text-black'}>Edit</NavLink>
                        <NavLink to={"#"} className={'bg-red-500 p-3 pl-5 pr-5 rounded-lg text-white ml-5'} onClick={(e) => {
                            e.preventDefault();

                            setDeleteId(row?.bankId);
                            setOpeDeleteModal(true);
                        }}>Delete</NavLink>
                    </>;
                }} /> : <p>No banks found.</p>)}
                {/* <div className="w-full md:w-3/12 pl-5 pr-5 flex justify-center mb-10">
                    <Link to={'#'} className="flex flex-col items-center justify-center w-full rounded-xl shadow-lg bg-white  pl-5 pr-5 pt-8 pb-8 hover:bg-gray-100" onClick={(e) => {e.preventDefault(); setOpenNewUserModal(true);}}>
                        <h2 className="text-2xl font-bold flex flex-col text-center text-green-700">
                            New account <svg className="w-6 h-6 ml-auto mr-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                        </h2>
                    </Link> 
                </div> */}
            </div>
        </div>
        <Modal open={openNewBankModal} setOpen={setOpenNewBankModal}>
            {/* <NewBank successCallback={setNewBankSuccess} key={'new-bank-form'} /> */}
        </Modal>
        <DeleteModal isOpen={openDeleteModal} setOpen={setOpeDeleteModal} additionalText={""} deleteCallback={() => deleteBank(deleteId)} />
    </LoggedInTemplate>;
}

export default BanksListing;