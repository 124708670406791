import { IAccount } from "../../../dto/account.dto";
import { Page } from "../../../dto/page.dto";
import ApiCall from "../../api.utils";

const AccountsList = (): Promise<Page<IAccount>> => {
    return ApiCall('/accounts', {
        'method': 'GET',
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(account => account.json());
}

export default AccountsList;