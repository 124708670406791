import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { INewAccountFormValues } from "../../../dto/account.dto";
import { IBank } from "../../../dto/bank.dto";
import BanksList from "../../../utilities/api/banks/banks.list";
import NewAccount from "../../../utilities/api/accounts/new.account";
import NewBank from "../../../utilities/api/banks/new.bank";
import AuthContext from "../../../utilities/auth.context";

const NewBankAccount:React.FunctionComponent<{
	successCallback: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
	let auth = React.useContext(AuthContext);
	
	const [banksLoaded, setBanksLoaded] = useState<boolean>(false);
	const [banks, setBanks] = useState<IBank[]>([]);

	useEffect(() => {
		if (!banksLoaded) {
			loadBanks();
			setBanksLoaded(true);
		}
	}, [banksLoaded]);

	const loadBanks = () => {
        BanksList().then(response => {
            setBanks(response.content);
        });
	}

	const { register, watch, handleSubmit, formState: { errors } } = useForm<INewAccountFormValues>();
	
	const onSubmit: SubmitHandler<INewAccountFormValues> = (data: INewAccountFormValues) => {
		if (auth.isAdmin() && data.newBank !== '') {
			NewBank(data.newBank).then((bank: IBank) => {
				NewAccount(data.name, data.description, Number(bank.bankId)).then(() => {
					props.successCallback(true);
				});
			})
		} else {
			NewAccount(data.name, data.description, Number(data.bankId)).then(() => {
				props.successCallback(true);
			});
		}
		
	}

	const isBankRequired = (value: number, isAdmin: boolean, newBank: string): string | boolean => {
		if (isAdmin) {
			return ((value === 0 && newBank !== '') || (value !== 0 && newBank !== '')) ? 'You must select a bank or create a new one': true;
		} else {
			return (value === 0) ? 'This field is required' : true;
		}
	}
	
	return <>
		<h2 className={"text-3xl font-bold text-center mb-5"}>New bank account</h2>
		
		<form className={"flex flex-col"} onSubmit={handleSubmit(onSubmit)}>

			<div className="flex flex-col mb-5">
				<label htmlFor="name" className={"font-bold pb-2"}>Account name <span className="text-red-600">*</span></label>
				<input type="text" id="name" {...register('name', {required: 'This field is required'})} placeholder="Main account, Savings account etc" className={"border rounded-md pt-2 pr-3 pb-2 pl-3 md:w-7/12"} />
				{errors.name ? (
					<div className="text-red-600">
						<ErrorMessage errors={errors} name="name" />
					</div>
				) : null}
			</div>
			
			<div className="flex flex-col mb-5">
				<label htmlFor="description" className={"font-bold pb-2"}>Description</label>
				<textarea id="description" {...register('description')} placeholder="A short description of what the account is used for" rows={5} className={"border rounded-md pt-2 pr-3 pb-2 pl-3"} />
			</div>

			
			<div className="flex flex-col mb-5">
				<label htmlFor="bank" className={"font-bold pb-3"}>Bank</label>
				<select id="bank" {...register('bankId', {
					validate: (value: number) => { return isBankRequired(value, auth.isAdmin(), watch('newBank'))}
				})} className={"border rounded-md pt-2 pr-3 pb-2 pl-3"}>
					<option value={0}>- Please select -</option>
					{banks.map((bank: IBank) => {
						return <option value={bank.bankId} key={'bank-' + bank.bankId}>{bank.name}</option>
					})}
				</select>
				{errors.bankId ? (
					<div className="text-red-600">
						<ErrorMessage errors={errors} name="bankId" />
					</div>
				) : null}
			</div>

			{auth.isAdmin() ? <div className={"flex flex-col p-5 bg-gray-100 rounded-lg mb-5"}>
				<p className={"text-gray-500 mb-5"}>- or -</p>

				<div className="flex flex-col mb-5">
					<label htmlFor="newBank" className={"font-bold pb-2"}>Add a new one</label>
					<input type="text" id="newBank" {...register('newBank')} placeholder="HSBC, Lloyds, TSB, Barclays etc" className={"border rounded-md pt-2 pr-3 pb-2 pl-3"} />
				</div>
			</div> : <></>}
			
			
			<button className={"bg-green-600 hover:bg-green-700 self-end rounded-md pt-2 pr-3 pb-2 pl-3 text-white flex flex-row"} type="submit">
				<span className={"pr-2"}>Create</span>
				<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
			</button>
		</form>
	</>;
}

export default NewBankAccount;