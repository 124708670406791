import ApiCall from '../../api.utils';

const NewAccount = (name: string, description: string, bankId: number): Promise<any> => {
	return ApiCall("/accounts", {
		'method': 'POST', 
		'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json; charset=UTF-8'
		},
		'body': JSON.stringify({
			name,
			description,
            bankId
		})
	});
}

export default NewAccount;