import ApiCall from '../../api.utils';

const InitiateRecurringTransactions = (accountId: number, date: string): Promise<any> => {
	return ApiCall('/accounts/' + accountId + '/recurring-transactions/instantiate', {
		'method': 'POST', 
		'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json; charset=UTF-8'
		},
		'body': JSON.stringify({
			date
		})
	});
}

export default InitiateRecurringTransactions;