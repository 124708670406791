import { IAccount } from "../../../dto/account.dto";
import ApiCall from "../../api.utils";

const GetAccount = (accountId: number): Promise<IAccount> => {
    return ApiCall('/accounts/' + accountId, {
        'method': 'GET', 
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(account => account.json());
}

export default GetAccount;