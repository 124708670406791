import ApiCall from "../../api.utils";
import { IPlannedTransaction } from '../../../dto/planned.transaction.dto';

const GetPlannedTransactions = (accountId: number, dateFrom: string, dateTo: string): Promise<IPlannedTransaction[]> => {
    return ApiCall('/accounts/' + accountId + '/planned-transactions?dateFrom=' + dateFrom + '&dateTo=' + dateTo, {
        'method': 'GET', 
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(
        response => response.json()
    ).then((plannedTransactions): IPlannedTransaction[] => {
        return plannedTransactions;
    });
}

export default GetPlannedTransactions;