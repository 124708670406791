import ApiCall from "../api.utils"


const GetMyDetails = () => {
    return ApiCall("/me", {
        'method': 'GET', 
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(user => user.json());
};

export default GetMyDetails;