import { IBank } from "../../../dto/bank.dto";
import { Page } from "../../../dto/page.dto";
import ApiCall from "../../api.utils";

const BanksList = (): Promise<Page<IBank>> => {
    return ApiCall('/banks', {
        'method': 'GET', 
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(bank => bank.json());
}

export default BanksList;