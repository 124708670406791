import React from 'react';
import LoggedInHeader from './header.component';
import LoggedInFooter from './footer.component';

interface ILoggedInTemplateProps {
	title: string;
	chart?: any;
	breadcrumb?: Array<{
		link: string;
		text: string;
	}>;
	children: any;
}

export const LoggedInTemplate: React.FunctionComponent<ILoggedInTemplateProps> = (props) => {
	return <>
		<LoggedInHeader title={props.title} chart={props.chart} breadcrumb={props.breadcrumb} />
			{props.children}
		<LoggedInFooter />
	</>;
}