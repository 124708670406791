import ApiCall from '../api.utils';

const login = (username: string, password: string): Promise<any> => {
	return ApiCall("/authenticate", {
		'method': 'POST', 
		'body': JSON.stringify({
			username,
			password
		})
	}).then(response => response.json())
}

export default login;