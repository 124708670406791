import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import GetOverallTotals from '../../../utilities/api/accounts/get.overall.totals';
import { IOverallTotals } from '../../../dto/overall.totals.dto';

export const NetWorthPanel = () => {
	const [overallTotals, setOverallTotals] = useState<IOverallTotals | undefined>(undefined);

	useEffect(() => {
		if (overallTotals === undefined) {
			GetOverallTotals().then((result: IOverallTotals) => {
				setOverallTotals(result);
			})
		}
	}, [overallTotals]);

	return <>{overallTotals !== undefined ? <div className="h-96"><ResponsivePie
		data={overallTotals.data}
		margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
		innerRadius={0.5}
		padAngle={0.7}
		cornerRadius={3}
		colors={{ scheme: 'nivo' }}
		borderWidth={1}
		borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
		enableArcLabels={false}
		legends={[
			{
				anchor: 'bottom',
				direction: 'row',
				justify: false,
				translateX: 0,
				translateY: 56,
				itemsSpacing: 0,
				itemWidth: 100,
				itemHeight: 18,
				itemTextColor: '#999',
				itemDirection: 'left-to-right',
				itemOpacity: 1,
				symbolSize: 18,
				symbolShape: 'circle',
				effects: [
					{
						on: 'hover',
						style: {
							itemTextColor: '#000'
						}
					}
				]
			}
		]}
	/></div> : <></>}</>
}