import ApiCall from '../../api.utils';

const NewBank = (name: string): Promise<any> => {
	return ApiCall("/banks", {
		'method': 'POST', 
		'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json; charset=UTF-8'
		},
		'body': JSON.stringify({
			name
		})
	}).then(response => response.json())
}

export default NewBank;