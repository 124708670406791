import ApiCall from "../../api.utils";
import { IOverallTotals, IOverallTotalsRemote } from "../../../dto/overall.totals.dto";

const GetOverallTotals = (): Promise<IOverallTotals> => {
    return ApiCall('/overall-totals-account-balance-chart', {
        'method': 'GET',
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(result => result.json()).then((result:IOverallTotalsRemote): IOverallTotals => {
        let returnVal:IOverallTotals = {
            data: []
        };
        result.totals.forEach((value) => returnVal.data.push({
            id: value.name,
            label: value.name,
            value: value.total
        }));

        return returnVal;
    });
}

export default GetOverallTotals;