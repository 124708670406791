import React from 'react';

interface AuthContextType {
    signin: (username: string, password: string) => void;
    signout: () => void;
    isAuthenticated: () => Promise<boolean>;
    isAdmin: () => boolean;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export default AuthContext;