import React, { useState, useEffect } from 'react';
import Login from '../login/login.component';
import AuthContext from '../../utilities/auth.context';
import { Outlet } from 'react-router-dom';

const PrivateRoute = () => {

  let auth = React.useContext(AuthContext);
  const [state, setState] = useState<any>('loading');

  useEffect(() => {
    (async function() {
      try {
        /* Update effect logic to track correct state */
        const isUserLogged = await auth.isAuthenticated();
        setState(isUserLogged ? 'loggedin' : 'login');
      }
      catch {
        setState('login');
      }
    })();
  }, [auth]);

  if(state === 'loading') {
    return <div>Loading..</div>
  }


  // let location = useLocation();
  return state === 'loggedin' ? <Outlet /> : <Login />;
}

export default PrivateRoute;