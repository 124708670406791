import React, { useEffect, useState } from 'react';
import { ISavingsPot } from '../../../../dto/savings.pot.dto';
import GetSavingsPotsWithAccountTotals from '../../../../utilities/api/accounts/get.savings.pots.with.accounts.totals';

export const SavingsTotalsPanel = (props: {
	accountId: number;
	dateTo: moment.Moment;
	refresh: boolean;
	refreshCallback?: Function;
}) => {

	const [total, setTotal] = useState<number>(0);
	const [savingsPots, setSavingsPots] = useState<ISavingsPot[]>([]);
	// const [accountTotals, setAccountTotals] = useState<Map<string, number>>(new Map<string,number>());
	// const [openModal, setOpenModal] = useState<boolean | null>(null);

	useEffect(() => {
		// if (openModal !== null) {
		// 	setOpenModal(null);
		// } else {
		// 	setOpenModal(null);

			GetSavingsPotsWithAccountTotals(props.accountId, props.dateTo?.format('YYYY-MM-DD')).then((result: ISavingsPot[]) => {
				setSavingsPots(result);
                let savingsTotal: number = 0;
                result.forEach((p) => { savingsTotal += p.totals.accountTotal });
                setTotal(savingsTotal);
			});
		// }

	}, [props.accountId, props.dateTo, props.refresh]);

	// function createSuccess() {
	// 	setOpenModal(false);
	// 	if (props.refreshCallback !== undefined) props.refreshCallback(true);
	// }

	// function openModalEvent(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
	// 	e.preventDefault(); 
	// 	setOpenModal(true);
	// }

	return <>
		<div className="flex justify-between">
			<h2 className="flex font-bold text-2xl mb-5">
				Savings pots
				{/* <button className="text-blue-500 self-center align-middle ml-2" onClick={(e) => { openModalEvent(e); }}><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></button> */}
			</h2>
			{/* <Modal open={openModal}>
				<NewSavingsPot successCallback={() => createSuccess()}/>
			</Modal> */}
			<p className={total >= 0 ? 'text-green-500 text-xl font-bold min-w-max' : 'text-red-500 text-xl font-bold min-w-max'}>
                {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(total)}
			</p>
		</div>
		{savingsPots.length > 0 ? savingsPots.map((pot: ISavingsPot) => {
			let potTotal = pot.totals.total;
			if (potTotal === undefined) potTotal = 0;
			const accountTotal = props.accountId != null ? pot.totals.accountTotal : 0;
			let totalWidth = ((potTotal > 0 ? potTotal : 0) / pot.goal)*100;

			let accountTotalWidth = (accountTotal !== undefined && accountTotal > 0) ? (accountTotal / pot.goal)*100 : 0;
            
            if (totalWidth > 100) totalWidth = 100;
            if (accountTotalWidth > 100) accountTotalWidth = 100;

			return <div className="mb-2" key={"savings-pot-" + pot.id}>
				<div className="flex justify-between mb-1">
					<h3 className="text-md font-bold">{pot.name}</h3>
					<p className={pot.totals.accountTotal === 0 || pot.totals.accountTotal === null ? 'text-gray-500' : pot.totals.accountTotal >= 0 ? 'text-green-500' : 'text-red-500'}>
                        {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(pot.totals.accountTotal)}
                    </p>
				</div>

				{props.accountId !== null ? <div className="w-full bg-gray-300 rounded-md relative">
					<div className={(accountTotalWidth === 100 ? 'bg-blue-600' : 'bg-green-600') + " rounded-md relative z-10 transition-all"} style={{width: accountTotalWidth+"%"}}>
						<p className="text-xs text-white pt-1 pr-2 pb-0 pl-2"></p>
					</div>
					<div className="bg-gray-600 rounded-md absolute top-0 left-0 z-0 transition-all" style={{width: totalWidth+"%"}}>
						<p className="text-xs text-white pt-1 pr-2 pb-0 pl-2"></p>
					</div>
				</div> : <div className="w-full bg-gray-300 rounded-md relative">
					<div className="bg-green-600 rounded-md relative z-10 transition-all" style={{width: totalWidth+"%"}}>
						<p className="text-xs text-white pt-1 pr-2 pb-0 pl-2"></p>
					</div>
				</div>}

			</div>

		}) : <p>No savings pots found.</p>}
	</>;
}