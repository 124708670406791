import ApiCall from "../../api.utils";

const GetLatestAccountTotal = (accountId: number, dateFrom: string, dateTo: string): Promise<number> => {
    return ApiCall('/accounts/' + accountId + '/balance?dateFrom=' + dateFrom + '&dateTo=' + dateTo, {
        'method': 'GET', 
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(
        response => response.json()
    ).then((total): number => {
        return total.balance;
    });
}

export default GetLatestAccountTotal;