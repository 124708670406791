import moment from 'moment';
import { useState, useEffect } from 'react';
import GetCalculatedAccountTotal from '../../../../utilities/api/accounts/get.calculated.account.total';

export const AccountTotalMinusBills = (props: {
	accountId: number,
	dateFrom: moment.Moment,
	dateTo: moment.Moment,
	refresh: boolean
}) => {

	const [total, setTotal] = useState<number>(0.00);

	useEffect(() => {
		GetCalculatedAccountTotal(props.accountId, props.dateFrom.format("yyyy-MM-DD"), props.dateTo.format("yyyy-MM-DD")).then((result: number) => {
			setTotal(result);
		});
	}, [props.accountId, props.dateFrom, props.dateTo, props.refresh]);

	return <div className={(total >= 0 ? "bg-green-600" : (total < 0 ? "bg-red-600" : "bg-gray-600")) + " p-10 mb-5 text-white rounded-xl shadow-lg border text-center"}>
		<p className="font-bold">Total (minus bills)</p>
		<p className="text-4xl font-bold">
            {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(total)}
        </p>
	</div>
}