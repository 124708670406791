import ApiCall from '../../api.utils';

const CreatePlannedTransaction = (accountId: number, description: string, category: number, savingsPot: number, transferAccount: number, amount: number, date: string): Promise<any> => {
	return ApiCall("/accounts/" + accountId + "/planned-transactions", {
		'method': 'POST', 
		'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json; charset=UTF-8'
		},
		'body': JSON.stringify({
			description,
            category,
            savingsPot,
            transferAccount,
            amount,
            date
		})
	}).then((response: Response) => {
		return response.headers.get("x-created-id");
	}).then((id: String | null): String | null => {
        return id;
    });
}

export default CreatePlannedTransaction;