import ApiCall from "../../api.utils";
import { IRecurringTransaction } from '../../../dto/recurring.transaction.dto';

const GetRecurringTransactions = (accountId: number): Promise<IRecurringTransaction[]> => {
    return ApiCall('/accounts/' + accountId + '/recurring-transactions', {
        'method': 'GET', 
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(
        response => response.json()
    ).then((recurringTransactions): IRecurringTransaction[] => {
        return recurringTransactions;
    });
}

export default GetRecurringTransactions;