import moment from 'moment';
import { useState, useEffect } from 'react';
import GetLatestAccountTotal from '../../../../utilities/api/accounts/account.total';
import UpdateAccountTotal from '../../../../utilities/api/accounts/update.account.total';

export const AccountTotal = (props: {
	accountId: number,
	dateFrom: moment.Moment,
	dateTo: moment.Moment,
	refresh: boolean,
	setRefresh: Function,
	overrideTitle?: string | undefined,
}) => {

	const [total, setTotal] = useState<number>(0.00);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [displayAccountTotalInput, setDisplayAccountTotalInput] = useState<boolean>(false);

	useEffect(() => {
		GetLatestAccountTotal(props.accountId, props.dateFrom.format("yyyy-MM-DD"), props.dateTo.format("yyyy-MM-DD")).then((result: number) => {
			setTotal(result);
			setRefresh(false);
		});
	}, [props.accountId, props.dateFrom, props.dateTo, props.refresh, refresh]);

	const setTotalValue = (e: any) => {
		setTotal(e.target.value);
	}

	const saveAccountTotal = (e: any) => {
		setTotal(Number(e.target.value));
		setDisplayAccountTotalInput(false);
		UpdateAccountTotal(props.accountId, total).then(() => {
			props.setRefresh(true);
		})
	}

	return <div className={"bg-gray-600 p-10 mb-5 text-white rounded-xl shadow-lg border text-center"}>
		<p className="font-bold">{props.overrideTitle !== undefined ? props.overrideTitle : 'Total'}</p>
		<p className="text-4xl font-bold">
			<span onClick={() => { if (moment() < props.dateTo) setDisplayAccountTotalInput(true)}} className={displayAccountTotalInput ? 'hidden' : ''}>
				{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(total)}
			</span>
			
			<span className={"w-auto " + (displayAccountTotalInput ? '' : 'hidden')}>
				&pound;<input 
					type={"text"} 
					value={total} 
					onChange={setTotalValue}
					onBlurCapture={saveAccountTotal}
					className={"bg-transparent w-auto"} />
			</span>
        </p>
	</div>
}