import React, { useEffect, useState } from 'react';
import { IPlannedTransaction } from '../../../../dto/planned.transaction.dto';
import { IRecurringTransaction } from '../../../../dto/recurring.transaction.dto';
import GetPlannedTransactions from '../../../../utilities/api/accounts/get.planned.transactions';
import GetRecurringTransactions from '../../../../utilities/api/accounts/get.recurring.transactions';
import InitiateRecurringTransactions from '../../../../utilities/api/accounts/initiate.recurring.transactions';
import { NewPlannedTransaction } from './planned-transactions/new.planned.transaction.component';
import { PlannedTransaction } from './planned-transactions/planned.transaction.component';
import { RecurringTransaction } from './recurring-transactions/recurring.transaction.component';
import { TableContainer, Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';

export const PlannedTransactionsPanel = (props: {
	accountId: number;
	dateFrom: moment.Moment;
	dateTo: moment.Moment;
	refresh: boolean;
	refreshCallback: Function;
}) => {

	const [refresh, setRefresh] = useState<boolean>(true);
	const [plannedTransactions, setPlannedTransactions] = useState<IPlannedTransaction[]>([]);
	const [recurringTransactions, setRecurringTransactions] = useState<IRecurringTransaction[]>([]);

	useEffect(() => {
        if (refresh) {
			GetPlannedTransactions(props.accountId, props.dateFrom.format("yyyy-MM-DD"), props.dateTo.format("yyyy-MM-DD")).then((result: IPlannedTransaction[]) => {
				setPlannedTransactions(result);
			});
			GetRecurringTransactions(props.accountId).then((result: IRecurringTransaction[]) => {
				setRecurringTransactions(result);
			});
		}
		setRefresh(false);
	}, [props.accountId, props.dateFrom, props.dateTo, props.refresh, refresh]);

	function initiate() {
		InitiateRecurringTransactions(props.accountId, props.dateTo.format("yyyy-MM-DD")).then(() => {
			setRefresh(true);
		});
	}

	function removeTransaction(idx: any) {
		let newPlannedTransactions = plannedTransactions;
		setPlannedTransactions(newPlannedTransactions.splice(idx,1));
	}
	
	return <>
		<div className="flex justify-between">
			<h2 className="flex flex-row font-bold text-2xl mb-5">
				Planned transactions 
			</h2>
		</div>
		{plannedTransactions.length > 0 ? <div><TableContainer>
				<Table size="small" aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell>&nbsp;</TableCell>
							<TableCell>&nbsp;</TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Description</TableCell>
							<TableCell>Pot</TableCell>
							<TableCell align="right">Amount</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{plannedTransactions.map((plannedTransaction: IPlannedTransaction, idx: any) => {
							return <PlannedTransaction 
								idx={idx}
								key={'planned-transaction-' + idx}
								plannedTransaction={plannedTransaction}
								refreshCallback={props.refreshCallback}
								removeTransaction={removeTransaction} />})}
					</TableBody>
				</Table>
			</TableContainer><NewPlannedTransaction accountId={props.accountId} plannedTransactions={plannedTransactions} setPlannedTransactions={setPlannedTransactions} dateTo={props.dateTo} /></div> : <div>
				<p>
					No planned transactions found. 
					<button onClick={initiate} className="text-blue-500 hover:text-blue-600 hover:underline">Click here</button> to initiate recurring transactions.
				</p>
				<h3 className="flex flex-row font-bold text-lg mt-5 mb-5">Recurring Transactions</h3>
				{recurringTransactions.length > 0 ? recurringTransactions.map((recurringTransaction: IRecurringTransaction, idx) => {
					return <RecurringTransaction 
						key={'recurring-transaction-' + idx} 
						recurringTransaction={recurringTransaction} 
						refreshCallback={props.refreshCallback} />}) : <></>}
				<p>Or, add a new planned transaction.</p>
				<NewPlannedTransaction accountId={props.accountId} plannedTransactions={plannedTransactions} setPlannedTransactions={setPlannedTransactions} dateTo={props.dateTo} />
			</div>}
	</>;
}