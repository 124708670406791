import { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import GetAccountChartTotals from '../../../../../../utilities/api/accounts/get.account.chart.totals';
import moment from 'moment';
import { IAccountChartTotal } from '../../../../../../dto/account.chart.total.dto';
import GetMortgageChartTotals from '../../../../../../utilities/api/accounts/get.mortgage.chart.totals';

export const PropertyMortgageChart = (props: {
    accountId: number;
    dateFrom: moment.Moment;
    dateTo: moment.Moment;
    refresh: boolean;
}) => {

	const [data, setData] = useState<Array<{x:string, y:number}>>([]);
    const [mortgageData, setMortgageData] = useState<Array<{x:string, y:number}>>([]);

	useEffect(() => {
		GetAccountChartTotals(props.accountId, props.dateFrom.format("yyyy-MM-DD"), props.dateTo.format("yyyy-MM-DD")).then((result: IAccountChartTotal[]) => {
			transformedData(result, setData);
		});
        GetMortgageChartTotals(props.accountId, props.dateFrom.format("yyyy-MM-DD"), props.dateTo.format("yyyy-MM-DD")).then((result: IAccountChartTotal[]) => {
			transformedData(result, setMortgageData);
		})
	}, [props.accountId, props.dateFrom, props.dateTo, props.refresh]);

    function transformedData(dataIn:IAccountChartTotal[], fn:Function): void {
        if (dataIn === undefined) return;
        let chartData: Array<{x:string, y:number}> = [];

        if (dataIn !== undefined && dataIn !== null) {
            dataIn.forEach((item) => {
                chartData.push({
                    x: item.dt,
                    y: item.balance
                });
            } );
        } 
        fn(chartData);
    }

	return <div className="h-72 w-full mt-10">
		<ResponsiveLine
			data={[{
                    id: "account-totals",
                    data: data
                },
                {
                    id: "mortgage-totals",
                    data: mortgageData
                }
            ]}
			margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
			xScale={{ type: 'point' }}
			yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
			yFormat=" >-.2f"
			curve="cardinal"
			axisTop={null}
			axisRight={null}
			axisBottom={null}
			axisLeft={null}
			colors="rgb(6, 78, 59)"
			enableGridX={false}
			enableGridY={false}
			lineWidth={5}
			enablePoints={false}
			pointSize={10}
			pointColor="rgb(6, 78, 59)"
			pointBorderWidth={2}
			pointBorderColor="rgb(6, 78, 59)"
			pointLabelYOffset={-12}
			useMesh={true}
			legends={[]} />
	  </div>

}