import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes
  } from "react-router-dom";
import PrivateRoute from './components/routing/private.route';
import Dashboard from './components/dashboard/dashboard.component';
import AccountsListing from './components/money/accounts/listing.component';
import AuthContext from './utilities/auth.context';
import { auth } from "./utilities/auth";
import { ViewAccount } from './components/money/accounts/view.account.component';
import UsersListing from './components/admin/users/listing.component';
import BanksListing from './components/admin/banks/listing.component';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return <AuthContext.Provider value={auth}>
	<Router>
			<Routes>
				{/* <PrivateRoute exact path="/property" component={PropertyListing} /> */}
				
				{/* <PrivateRoute exact path="/" component={Dashboard} /> */}

				<Route path='/' element={<PrivateRoute/>}>
					<Route path='/' element={<Dashboard />} />

					{/* Admin routes */}
					<Route path="/users" element={<UsersListing />} />
					<Route path="/banks" element={<BanksListing />} />

					{/* Protected routes */}

					<Route path="/bank-accounts" element={<AccountsListing />} />
					<Route path="/bank-accounts/:accountId" element={<ViewAccount />} />
				</Route>

				{/* <Route component={Error404} /> */}
			</Routes>
		</Router>
	</AuthContext.Provider>;
}

export default App;
