import moment from 'moment';
import CreatePlannedTransaction from '../../../../../utilities/api/accounts/new.planned.transaction';
import { IPlannedTransaction } from '../../../../../dto/planned.transaction.dto';

export const NewPlannedTransaction:React.FunctionComponent<{
	accountId: number;
	plannedTransactions: IPlannedTransaction[]
	setPlannedTransactions: Function;
	dateTo: moment.Moment;
}> = (props) => {
	
	function create() {

		let plannedTransaction: IPlannedTransaction = {
			id: 0,
			description: "Replace me",
			amount: 0,
			processed: false,
			plannedTransactionDate: props.dateTo.diff(moment().format('YYYY-MM-DD')) > 0 ? props.dateTo.day(1).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
			accountId: props.accountId,
			category: {
				categoryId: 2,
				code: "spending",
				name: "Spending",
				description: "Spending"
			},
			savingsPot: {
				id: "0",
				name: "",
				goal: 0,
				totals: {
					total: 0,
    				accountTotal: 0
				}
			}
		}

		CreatePlannedTransaction(plannedTransaction.accountId, 
			plannedTransaction.description, 
			plannedTransaction.category.categoryId, 
			Number(plannedTransaction.savingsPot.id), 
			0, 
			plannedTransaction.amount, 
			plannedTransaction.plannedTransactionDate).then((id) => {
			plannedTransaction.id = id;
			props.setPlannedTransactions([...props.plannedTransactions, plannedTransaction]);
		});
	}

	return <p className={"text-center p-3"}><span className={"bg-green-900 rounded-full pr-4 pl-4 pt-3 pb-3 text-white font-bold cursor-pointer"} onClick={(e) => {e.preventDefault(); create();}}>+</span></p>

}