import GetMyDetails from "./api/get.my.details";
import login from "./api/login";

const auth = {
	async signin(username: string, password: string): Promise<any> {
		return login(username, password)
		.then(response => {
			localStorage.setItem('token', response.token);
			localStorage.setItem('refreshToken', response.refreshToken);
			localStorage.setItem('user.role', response.role);

			GetMyDetails().then(user => {
				localStorage.setItem('user.nickname', user.nickname);
				localStorage.setItem('user.initials', user.initials);
			})
			.catch(error => {
				return false;
			})
			.finally(() => {
				window.location.reload();
			});

			return true;
		}).catch(error => {
			return false;
		});
	},
	signout(): void {
		localStorage.removeItem('token');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('user.role');
		localStorage.removeItem('user.nickname');
		localStorage.removeItem('user.initials');
	},
	isAuthenticated(): Promise<boolean> {
		const token: string | null = localStorage.getItem('token');
		if (token) {
			try {
				return window.fetch(process.env.REACT_APP_API_ENDPOINT + "/verify", {
					'method': 'GET', 
					'mode': 'cors',
					'cache': 'no-cache',
					'credentials': 'same-origin',
					'headers': {
						'Authorization': 'Bearer ' + localStorage.getItem('token'),
						'Content-Type': 'application/json; charset=UTF-8'
					},
					'redirect': 'follow', // manual, *follow, error
					'referrerPolicy': 'no-referrer'
				})
				.then(response => {
					if (response.ok) {
						return Promise.resolve(true);
					} else {
						auth.signout();
						return Promise.reject(false);
					}
				})
				.catch(error => {
					auth.signout();
					return Promise.reject(false);
				});
			} catch(err) {
				auth.signout();
				return Promise.reject(false);
			}
		}
		return Promise.reject(false);
	},
	isAdmin(): boolean {
		return localStorage.getItem('user.role') === 'ADMIN';
	}
};
  
export { auth };
  