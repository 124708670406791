import ApiCall from "../../api.utils";
import { ICategory } from '../../../dto/category.dto';

const GetCategories = (): Promise<ICategory[]> => {
    return ApiCall('/accounts/categories', {
        'method': 'GET',
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }).then(categories => categories.json());
}

export default GetCategories;