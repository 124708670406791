import React from 'react';
import { LoggedInTemplate } from '../../templates/loggedin/base';
import { SavingsTotalsPanel } from './inc/savings.totals.panel.component';
import { NetWorthPanel } from './inc/net.worth.panel.component';

interface IDashboardProps {}
interface IDashboardState {}

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {

	render() {
		return <>
			<LoggedInTemplate title="dashboard" chart="">
				<div className="max-w-7xl mx-auto -mt-8">
					<div className="flex flex-wrap items-start flex-row">
						<div className="w-full md:w-6/12 pl-5 pr-5 mb-10">
							<div className="bg-white rounded-xl p-10 shadow-lg mb-10">
								<NetWorthPanel />
							</div>
							<div className="bg-green-600 text-white text-center rounded-xl p-10 shadow-lg">
								{/* <EmergencyFundPanel /> */}EmergencyFundPanel
							</div>
						</div>
						<div className="w-full md:w-6/12 pl-5 pr-5">
							<div className="bg-white rounded-xl p-10 shadow-lg">
								<SavingsTotalsPanel />
							</div>
						</div>
					</div>
				</div>
			</LoggedInTemplate>
		</>;
	}
}

export default Dashboard;
