const ApiCall = (url: string, options: any) => {
    const defaultOptions = {
        mode: 'cors',
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json; charset=UTF-8'
		},
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer',
    };
    const fetchOptions = {...defaultOptions, ...options};

    return window.fetch(process.env.REACT_APP_API_ENDPOINT + url, fetchOptions);
}

export default ApiCall