import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { IAccount, IStructuredAccount } from '../../../dto/account.dto';
import { LoggedInTemplate } from '../../../templates/loggedin/base';
import AccountsList from '../../../utilities/api/accounts/accounts.list';
import { Modal } from '../../shared/components/modal.component';
import BankAccount from './inc/bank.account.component';
import BankTotalPanel from './inc/bank.total.panel.component';
import NewBankAccount from './new.account.component';

const AccountsListing = () => {

    const [refresh, setRefresh] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<IAccount[]>([]);
    const [structuredAccounts, setStructuredAccounts] = useState<IStructuredAccount[]>([]);
    const [openNewAccountModal, setOpenNewAccountModal] = useState<boolean>(false);
    const [newAccountSuccess, setNewAccountSuccess] = useState<boolean>(false);

	useEffect(() => {
		if (refresh) setRefresh(false);
        if (newAccountSuccess) {
            setNewAccountSuccess(false);
            setOpenNewAccountModal(false);
        }
        if (refresh || newAccountSuccess || accounts.length === 0) {
            loadAccounts();
        }
        if (accounts.length > 0) {
            getStructuredAccounts();
        }
	}, [refresh, newAccountSuccess, accounts]);

    const loadAccounts = () => {
        AccountsList().then(response => {
            setAccounts(response.content);
        });
    }

    const getStructuredAccounts = (): void => {
        if (accounts.length > 0) {

            const mappedAccounts: IStructuredAccount[] = [];
            
            accounts.map((account: IAccount) => {
                const accountType = account.accountType.name;

                let mappedAccount: IStructuredAccount | undefined = mappedAccounts.find((val) => val.accountType === accountType);

                if (mappedAccount === undefined) {
                    mappedAccount = {accountType, accounts: []};
                    mappedAccounts.push(mappedAccount);
                }

                const index = mappedAccounts.indexOf(mappedAccount);

                mappedAccount.accounts.push(account);

                mappedAccounts[index] = mappedAccount;

                return mappedAccount;
            })

            
            setStructuredAccounts(mappedAccounts);
        }
    }

    return <LoggedInTemplate title="money" chart={<BankTotalPanel refresh={refresh} />}>
        <div className="max-w-7xl mx-auto -mt-8">
            <div className="flex flex-wrap items-start flex-row">
                {structuredAccounts.length > 0 ? structuredAccounts.map((structuredAccount: IStructuredAccount, index: number) => {
                    return <>
                        <h2 className={'text-2xl font-bold flex flex-row w-full'} key={'account-type-' + index}>{structuredAccount.accountType}</h2>
                        {structuredAccount.accounts.length > 0 ? structuredAccount.accounts.map((account: IAccount) => {
                            return <BankAccount account={account} deleteSuccess={setRefresh} key={'account-' + account.accountId} />
                        }): ''}
                    </>
                }) : ''}
            </div>
            <div className="w-full md:w-3/12 pl-5 pr-5 flex flex-row justify-center mb-10">
                <Link to={'#'} className="flex flex-col items-center justify-center w-full rounded-xl shadow-lg bg-white  pl-5 pr-5 pt-8 pb-8 hover:bg-gray-100" onClick={(e) => {e.preventDefault(); setOpenNewAccountModal(true);}}>
                    <h2 className="text-2xl font-bold flex flex-col text-center text-green-700">
                        New account <svg className="w-6 h-6 ml-auto mr-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    </h2>
                </Link> 
            </div>
        </div>
        <Modal open={openNewAccountModal} setOpen={setOpenNewAccountModal}>
            <NewBankAccount successCallback={setNewAccountSuccess} key={'new-account-form'} />
        </Modal>
    </LoggedInTemplate>;
}

export default AccountsListing;