import { IPlannedTransaction } from '../../../../../dto/planned.transaction.dto';
import moment from 'moment';
import { Icon } from '../../../../shared/components/icon.component';
import ProcessPlannedTransaction from '../../../../../utilities/api/accounts/process.planned.transaction';
import { TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import { EditPlannedTransaction } from './edit.planned.transaction.component';

export const PlannedTransaction = (props: {
	idx: any;
	plannedTransaction: IPlannedTransaction;
	refreshCallback: Function;
	removeTransaction: Function;
}) => {

	const [isUpdating, setIsUpdating] = useState<boolean>(false);
	const [plannedTransaction, setPlannedTransaction] = useState<IPlannedTransaction>(props.plannedTransaction);

	function processTransaction() {
		ProcessPlannedTransaction(plannedTransaction.id, plannedTransaction.accountId).then(() => {
			props.refreshCallback(true);
		});
	}

	return isUpdating ? <EditPlannedTransaction plannedTransaction={plannedTransaction} setIsUpdating={setIsUpdating} setPlannedTransaction={setPlannedTransaction} removeTransaction={props.removeTransaction} idx={props.idx} /> : <TableRow
		key={'planned-transaction-' + plannedTransaction.id}
		sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
	>
		<TableCell component="th" scope="row">
			{plannedTransaction.processed ? 
				<svg onClick={processTransaction} className="w-6 h-6 text-green-700 cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg> : 
				<svg onClick={processTransaction} className="w-6 h-6 text-red-500 cursor-pointer" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" /></svg>
			}
		</TableCell>
		<TableCell component="th" scope="row" onClick={() => setIsUpdating(true)}>
			<Icon type={plannedTransaction.category.code} />
		</TableCell>
		<TableCell onClick={() => setIsUpdating(true)}>
			{moment(plannedTransaction.plannedTransactionDate).format('DD/MM/YYYY')}
		</TableCell>
		<TableCell onClick={() => setIsUpdating(true)}>
			{plannedTransaction.description}
		</TableCell>
		<TableCell onClick={() => setIsUpdating(true)}>
			{plannedTransaction.savingsPot !== null ? <>{plannedTransaction.savingsPot.name}</> : '' }
		</TableCell>
		<TableCell onClick={() => setIsUpdating(true)} align="right" color={plannedTransaction.amount >= 0 ? 'green' : 'red'} className={plannedTransaction.amount >= 0 ? 'text-green-500 min-w-max' : 'text-red-500 min-w-max'}>
			{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(plannedTransaction.amount)}
		</TableCell>
	</TableRow>;
}