import ApiCall from '../../api.utils';

const ProcessPlannedTransaction = (plannedTransactionId: number, accountId: number): Promise<any> => {
	return ApiCall("/accounts/" + accountId + "/planned-transactions/" + plannedTransactionId + "/change-processed", {
		'method': 'POST', 
		'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json; charset=UTF-8'
		}
	});
}

export default ProcessPlannedTransaction;