import ApiCall from '../../api.utils';

const DeletePlannedTransaction = (accountId: number, plannedTransactionId: number): Promise<any> => {
	return ApiCall("/accounts/" + accountId + "/planned-transactions/" + plannedTransactionId, {
		'method': 'DELETE', 
		'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json; charset=UTF-8'
		}
	});
}

export default DeletePlannedTransaction;