import ApiCall from "../../api.utils";

const UpdatePropertyMortgageTotal = (accountId: number, balance: number): Promise<number> => {
    return ApiCall('/accounts/' + accountId + '/mortgage-balance', {
        'method': 'POST', 
        'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json; charset=UTF-8'
        },
		'body': JSON.stringify({
			balance
		})
    }).then(
        response => response.json()
    ).then((total): number => {
        return total.balance;
    });
}

export default UpdatePropertyMortgageTotal;