import { useEffect, useState } from 'react';
import { ISavingsPot } from '../../../../../dto/savings.pot.dto';
import GetSavingsPotsTotals from '../../../../../utilities/api/accounts/get.savings.pots.totals';
import { ICategory } from '../../../../../dto/category.dto';
import GetCategories from '../../../../../utilities/api/accounts/get.categories';
import { ErrorMessage } from '@hookform/error-message';
import { IPlannedTransactionFormValues, IPlannedTransaction } from '../../../../../dto/planned.transaction.dto';
import { TableRow, TableCell } from '@mui/material';
import UpdatePlannedTransaction from '../../../../../utilities/api/accounts/update.planned.transaction';
import { useForm } from 'react-hook-form';
import DeletePlannedTransaction from '../../../../../utilities/api/accounts/delete.planned.transaction';

export const EditPlannedTransaction:React.FunctionComponent<{
	plannedTransaction: IPlannedTransaction;
	setIsUpdating: Function;
	setPlannedTransaction: Function;
	removeTransaction: Function;
	idx: any;
}> = (props) => {
	const [categoriesLoaded, setCategoriesLoaded] = useState<boolean>(false);
	const [categories, setCategories] = useState<ICategory[]>([]);
	const [savingsPotsLoaded, setSavingsPotsLoaded] = useState<boolean>(false);
	const [savingsPots, setSavingsPots] = useState<ISavingsPot[]>([]);
	const [isDeleted, setIsDeleted] = useState<boolean>(false);

	useEffect(() => {
        
		if (!categoriesLoaded) {
			fetchCategories();
			setCategoriesLoaded(true);
		}

		if (!savingsPotsLoaded) {
			fetchSavingsPots();
			setSavingsPotsLoaded(true);
		}


    }, [categoriesLoaded, savingsPotsLoaded]);
    
	const fetchCategories = () => {
		GetCategories().then((result: ICategory[]) => {
			setCategories(result);
		});
	}

	const fetchSavingsPots = () => {
		GetSavingsPotsTotals().then((result: ISavingsPot[]) => {
			setSavingsPots(result);
		});
	}

	const { register, getValues, formState: { errors } } = useForm<IPlannedTransactionFormValues>({
		defaultValues: {
			processed: props.plannedTransaction.processed,
			category: props.plannedTransaction.category.categoryId,
			date: props.plannedTransaction.plannedTransactionDate,
			description: props.plannedTransaction.description,
			savingsPot: props.plannedTransaction.savingsPot != null ? Number(props.plannedTransaction.savingsPot.id) : 0,
			amount: props.plannedTransaction.amount
		}
	  });
    
	const onSubmit = () => {
		const values = getValues();
		
		UpdatePlannedTransaction(
			props.plannedTransaction.id, 
			props.plannedTransaction.accountId, 
			values.description, 
			Number(values.category), 
			Number(values.savingsPot), 
			Number(values.amount), 
			values.date).then((plannedTransaction: IPlannedTransaction) => {
				props.setPlannedTransaction(plannedTransaction);
				props.setIsUpdating(false);
			});
	}

	const deletePlannedTransaction = () => {
		DeletePlannedTransaction(props.plannedTransaction.accountId, props.plannedTransaction.id).then(() => {
			setIsDeleted(true);
		});
	}

	return isDeleted ? <></> : <>
		<TableRow
			key={'planned-transaction-' + props.plannedTransaction.id}
			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		>
			<TableCell component="th" scope="row">
				{props.plannedTransaction.processed ? 
					<svg className="w-6 h-6 text-green-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg> : 
					<svg className="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" /></svg>
				}
			</TableCell>
			<TableCell component="th" scope="row">
				{categoriesLoaded ? <><select {...register('category', {required: 'This field is required', valueAsNumber: true, value: props.plannedTransaction.category.categoryId})} className={"border rounded-md pt-2 pr-3 pb-2 pl-3"}>
					<option value="0">- Please select -</option>
					{categories.map((category: ICategory) => {
						return <option 
							value={category.categoryId} 
							key={'category-' + category.categoryId}>{category.name}</option>
					})}
				</select>
				{errors.category ? (
					<div className="text-red-600">
						<ErrorMessage errors={errors} name="category" />
					</div>
				) : null}</>: "Loading..."}
			</TableCell>
			<TableCell>
				<input type="date" id="date" {...register('date', {required: 'This field is required'})} placeholder="x.xx" className={"border rounded-md pt-2 pr-3 pb-2 pl-3"} />
			</TableCell>
			<TableCell>
				<input type="text" id="description" {...register('description', {required: 'This field is required'})} className={"border rounded-md pt-2 pr-3 pb-2 pl-3"} />
			</TableCell>
			<TableCell>
				{savingsPotsLoaded ? <><select id="savingsPot" {...register('savingsPot', {valueAsNumber: true, value: props.plannedTransaction.savingsPot != null ? Number(props.plannedTransaction.savingsPot.id) : 0})} className={"border rounded-md pt-2 pr-3 pb-2 pl-3"}>
					<option value="0">- Please select -</option>
					{savingsPots.map((savingsPot: ISavingsPot) => {
						return <option 
							value={savingsPot.id} 
							key={'savingsPot-' + savingsPot.id}>{savingsPot.name}</option>
					})}
				</select>
				{errors.savingsPot ? (
					<div className="text-red-600">
						<ErrorMessage errors={errors} name="savingsPot" />
					</div>
				) : null}</> : "Loading..."}
			</TableCell>
			<TableCell align="right" color={props.plannedTransaction.amount >= 0 ? 'green' : 'red'} className={props.plannedTransaction.amount >= 0 ? 'text-green-500 min-w-max' : 'text-red-500 min-w-max'}>
				<input type="number" id="amount" {...register('amount', {required: 'This field is required', valueAsNumber: true})} placeholder="x.xx" step=".01" className={"border rounded-md pt-2 pr-3 pb-2 pl-3"} />
			</TableCell>
		</TableRow>
		<TableRow
				key={'planned-transaction-actions-' + props.plannedTransaction.id}
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			>
			<TableCell colSpan={6}>
				<div className="flex flex-row justify-end mb-5">
					<button className={"bg-red-600 hover:bg-red-700 rounded-md pt-2 pr-3 pb-2 pl-3 text-white"} onClick={deletePlannedTransaction}>
						<span>Delete</span>
					</button>
					<button className={"bg-yellow-600 hover:bg-yellow-700 rounded-md pt-2 pr-3 pb-2 pl-3 ml-4 text-white"} onClick={() => props.setIsUpdating(false)}>
						<span>Cancel</span>
					</button>
					<button className={"bg-green-600 hover:bg-green-700 rounded-md pt-2 pr-3 pb-2 pl-3 ml-2 text-white"} onClick={onSubmit}>
						<span>Update</span>
					</button>
				</div>
			</TableCell>
		</TableRow>
	</>

}