import { IPlannedTransaction } from '../../../dto/planned.transaction.dto';
import ApiCall from '../../api.utils';

const UpdatePlannedTransaction = (plannedTransactionId: number, accountId: number, description: string, category: number, savingsPot: number, amount: number, date: string): Promise<any> => {
	return ApiCall("/accounts/" + accountId + "/planned-transactions/" + plannedTransactionId, {
		'method': 'POST', 
		'headers': {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json; charset=UTF-8'
		},
		'body': JSON.stringify({
			description,
            category,
            savingsPot,
            amount,
            date
		})
	})
	.then(response => response.json())
	.then((plannedTransaction: IPlannedTransaction) => {
        return plannedTransaction;
    });
}

export default UpdatePlannedTransaction;